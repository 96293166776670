import React, { Fragment, useEffect, useState } from 'react';
import {Container, Row, Col, Card} from 'react-bootstrap';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { CardProducto } from '../components/CardProducto';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import Cookies from 'universal-cookie';

import "./estilos.css";
import axios from 'axios';


function LandingPage () {

    const cookies = new Cookies();
    const accessToken = cookies.get('accesToken');

    const [arrayProductosNuevos, setArrayProductosNuevos] = useState([]);
    const [arrayProductosMasVendidos, setArrayProductosMasVendidos] = useState([]);
    const [arrayProductosDestacados, setArrayProductosDestacados] = useState([]);
    const [arrayProductosPromocionales, setArrayProductosPromocionales] = useState([]);
    const [arrayBanner, setArrayBanner] = useState([]);
    const [imagenWhatsapp, setimagenWhatsapp] = useState([]);

    let imagenPublicidad = process.env.REACT_APP_SERVER_IMAGE+'estaticas/';


    var settingsMarcas = {
        dots: false,
        infinite: true,
        slidesToShow: 6,
        slidesToScroll: 2,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            }
        ]
    };
    var settingsBanners = {
        dots: false,
        infinite: true,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    var settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        speed: 500,
        autoplaySpeed: 3000,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 620,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 440,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 320,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    const arrayMarcas = [
        {
            imagen: process.env.REACT_APP_SERVER_IMAGE+'marcas/marca1.webp',
            nombre: 'Marca 1'
        },
        {
            imagen: process.env.REACT_APP_SERVER_IMAGE+'marcas/marca2.webp',
            nombre: 'Marca 2'
        },
        {
            imagen: process.env.REACT_APP_SERVER_IMAGE+'marcas/marca3.webp',
            nombre: 'Marca 3'
        },
        {
            imagen: process.env.REACT_APP_SERVER_IMAGE+'marcas/marca4.webp',
            nombre: 'Marca 4'
        },
        {
            imagen: process.env.REACT_APP_SERVER_IMAGE+'marcas/marca5.webp',
            nombre: 'Marca 5'
        },
        {
            imagen: process.env.REACT_APP_SERVER_IMAGE+'marcas/marca6.webp',
            nombre: 'Marca 6'
        },
        {
            imagen: process.env.REACT_APP_SERVER_IMAGE+'marcas/marca7.webp',
            nombre: 'Marca 7'
        },
        {
            imagen: process.env.REACT_APP_SERVER_IMAGE+'marcas/marca8.webp',
            nombre: 'Marca 8'
        },
        {
            imagen: process.env.REACT_APP_SERVER_IMAGE+'marcas/marca9.webp',
            nombre: 'Marca 9'
        },
        {
            imagen: process.env.REACT_APP_SERVER_IMAGE+'marcas/marca10.webp',
            nombre: 'Marca 10'
        },
        {
            imagen: process.env.REACT_APP_SERVER_IMAGE+'marcas/marca11.webp',
            nombre: 'Marca 11'
        },
        {
            imagen: process.env.REACT_APP_SERVER_IMAGE+'marcas/marca12.webp',
            nombre: 'Marca 12'
        },
        {
            imagen: process.env.REACT_APP_SERVER_IMAGE+'marcas/marca13.webp',
            nombre: 'Marca 13'
        },
        {
            imagen: process.env.REACT_APP_SERVER_IMAGE+'marcas/marca14.webp',
            nombre: 'Marca 14'
        },
        {
            imagen: process.env.REACT_APP_SERVER_IMAGE+'marcas/marca15.webp',
            nombre: 'Marca 15'
        },
        {
            imagen: process.env.REACT_APP_SERVER_IMAGE+'marcas/marca16.webp',
            nombre: 'Marca 16'
        },
        {
            imagen: process.env.REACT_APP_SERVER_IMAGE+'marcas/marca17.webp',
            nombre: 'Marca 17'
        },
        {
            imagen: process.env.REACT_APP_SERVER_IMAGE+'marcas/marca18.webp',
            nombre: 'Marca 18'
        },
        {
            imagen: process.env.REACT_APP_SERVER_IMAGE+'marcas/marca19.webp',
            nombre: 'Marca 19'
        },
        {
            imagen: process.env.REACT_APP_SERVER_IMAGE+'marcas/marca20.webp',
            nombre: 'Marca 20'
        },
        {
            imagen: process.env.REACT_APP_SERVER_IMAGE+'marcas/marca21.webp',
            nombre: 'Marca 21'
        },
    ];

    const queryFetch = async () => {
        try {
            const urlBanner = process.env.REACT_APP_SERVER + '/api/v1/products/banner';
            const dataBanner = await axios.post(urlBanner, {pagina: 'landingPage'}, {
                headers: {
                    'Authorization':`Bearer ${accessToken}`
                }
            });
            const Banner = dataBanner.data;  

            const urlBannerW = process.env.REACT_APP_SERVER + '/api/v1/products/banner';
            const dataBannerW = await axios.post(urlBannerW, {pagina: 'whatsapp'}, {
                headers: {
                    'Authorization':`Bearer ${accessToken}`
                }
            });
            const BannerW = dataBannerW.data;  
            
            const url = process.env.REACT_APP_SERVER + '/api/v1/products/landingPage';
            const response = await axios.get(url);
            const data = response.data;
            
            let masVendidos = data.filter(element => element.categoria === 'Más Vendido');
            let nuevo = data.filter(element => element.categoria === 'Nuevo');
            let destacado = data.filter(element => element.categoria === 'Destacado');
            let promocional = data.filter(element => element.categoria === 'Promocional');
            
            setArrayBanner(Banner);
            setimagenWhatsapp(BannerW);
            setArrayProductosMasVendidos(masVendidos);
            setArrayProductosNuevos(nuevo);
            setArrayProductosDestacados(destacado);
            setArrayProductosPromocionales(promocional);
            
            window.scroll({
                top: 0,
                left: 100,
                behavior: 'auto'
              });
        } catch (error) {
            console.log('Ocurrió error en la consulta' + error);
        }
    }


    useEffect(() => {
        queryFetch();
    },[]);
    
    return(
        <>
            <Header />
            <Container fluid="md">
                <Row className="align-items-center banner" aria-hidden="true">
                    <Col className='sliderBanner'>
                        <div className='flecha'>
                            <Slider {...settingsBanners} >
                                    {
                                        arrayBanner.map((fila, id) =>
                                        <Col sm={10} key={id}>
                                            <p></p>
                                            <a key={id} target={"_new"} href={fila.telefono}>
                                                <img fallback={'Cargando'} src={imagenPublicidad + fila.pagina} alt="bannerPrincipal" className="img-fluid imgGrande" width={'100%'} height={'100%'}/>
                                            </a>
                                        </Col>
                                        )
                                    }                                    
                            </Slider>
                        </div>
                    </Col>
                </Row>
                <Row className="align-items-center mt-0" style={{height:'100%'}}>
                    <Col className="mt-4" sm={11} md={5} lg={6}>
                        <iframe title="facebook" src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FFerreteriaMaster1&tabs&width=320&height=130&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=false&appId" width="320" height="130" style={{border:'none',overflow:'hidden'}}  scrolling="no" allowFullScreen={true} allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                    </Col>
                    <Col sm={11} md={4} lg={6}>
                        <iframe id='instagram' title='instagram' src="https://www.instagram.com/ferreteria.master/embed" scrolling="no" width="100%" height="130px" allowtransparency="true"></iframe>
                    </Col>
                </Row> 
                <Row className="align-items-center">
                    <Col sm={11}>
                        <h4>PRODUCTOS PROMOCIONALES</h4>
                    </Col>
                </Row>
                <Row>
                    <Col className="carruselResponsive">
                        <Slider {...settings}>
                            {
                                arrayProductosPromocionales.map((item, i) => 
                                    <Fragment key={i}>
                                        <CardProducto propiedades={item} />
                                    </Fragment>
                                )
                            }
                        </Slider> 
                    </Col>
                </Row>

                <Row className="align-items-center">
                    <Col sm={11}>
                        <h4>PRODUCTOS NUEVOS</h4>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} className="carruselResponsive">
                        <Slider {...settings}>
                            {
                                arrayProductosNuevos.map((item, i) => 
                                <Fragment key={i}>
                                        <CardProducto propiedades={item} />
                                    </Fragment>
                                )
                            }
                        </Slider> 
                    </Col>
                </Row>

                <Row className="align-items-center">
                    <Col sm={11}>
                        <h4>PRODUCTOS DESTACADOS</h4>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} className="carruselResponsive">
                        <Slider {...settings}>
                            {
                                arrayProductosDestacados.map((item, i) => 
                                    <Fragment key={i}>
                                        <CardProducto propiedades={item} />
                                    </Fragment>
                                )
                            }
                        </Slider> 
                    </Col>
                </Row>

                <Row className="align-items-center">
                    <Col sm={11}>
                        <h4>PRODUCTOS MÁS VENDIDOS</h4>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} className="carruselResponsive">
                        <Slider {...settings}>
                            {
                                arrayProductosMasVendidos.map((item, i) => 
                                    <Fragment key={i}>
                                        <CardProducto propiedades={item} />
                                    </Fragment>
                                )
                            }
                        </Slider> 
                    </Col>
                </Row>
                <br/>
                <Row className="align-items-center" aria-hidden="true">
                    <Col sm={11}>
                    <h4>MARCAS</h4>
                    </Col>
                </Row>

                <Row className="align-items-center">
                    <Col  className=" carruselResponsive carruselMarca">
                        <Slider {...settingsMarcas}>
                            {
                                arrayMarcas.map((item, i) => 
                                <Row key={i}>
                                    <Col   className='imgMarcas' >
                                        <img loading='lazy' fallback={<p>Cargando</p>} id={i} className="mx-auto img-fluid imgMarca" src={item.imagen} alt={item.nombre} width={'90%'} height={'100%'}/>
                                    </Col>
                                </Row>
                                )
                            }
                        </Slider>
                        <br></br>
                    </Col>
                </Row>
                <br></br>
                {/* <lable>window.screen.width {window.screen.width} y window.screen.height {window.screen.height}</lable> */}
            </Container>
            {
                imagenWhatsapp.map((fila, id) =>
                    <a key={id} className='btn-wsp' target={"_new"} href={fila.telefono} aria-label="Número de whatsApp de nuestros asesores">
                        <Card.Img className='btnWsp1' alt="75x75" src={imagenPublicidad + fila.pagina}/>
                    </a>
                )
            } 
            <Footer />
        </>
    )
}

export {LandingPage};